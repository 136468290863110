export type ThemeType = typeof light; // This is the type definition for my theme object.

export const light = {
  colors: {
    primary: "#B6F8BD",
    secondary: "#2A6D7F",
    text: "#2D2E30",
    textlight: "#151617",
    background: "#FBFCFE",
    backgrounddark: "#DFE0E1",
  },
};

const theme = light; // set the light theme as the default.
export default theme;
