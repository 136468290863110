import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import demo from "./demo.png";
import logo from "./splurv_logo.png";
import color from "color";
import "./App.css";

const FloatingNavDiv = styled.div`
  @media (max-width: 1040px) {
    display: none;
  }
  position: fixed;
  top: 0;
  right: 0;
  padding: 0;
  padding-right: 1rem;
  background: rgba(0, 0, 0, 0.3);
  font-family: PhosphateSolid;
  font-size: 2rem;
  color: white;
  ul {
    margin-left: 0 !important;
    padding-left: 1rem !important;
    p {
      cursor: pointer;
      &.active {
        color: ${(props) => props.theme.colors.primary};
      }
      &.activewhite {
        color: ${(props) => props.theme.colors.primary};
      }
      &.not {
        color: ${(props) => props.theme.colors.secondary};
        :hover {
          color: ${(props) => props.theme.colors.primary};
        }
      }
    }
  }
`;

const Section = styled.section`
  height: calc(100vh - 3rem);
  color: ${(props) => props.theme.colors.text};
  padding: 1.5rem;
  @media (max-width: 700px) {
    padding: 1rem;
    height: calc(100vh - 2rem);
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  header {
    font-family: PhosphateSolid;
    font-size: 9rem;
    margin: 0;
  }
  h2 {
    font-family: PhosphateSolid;
    font-size: 2rem;
    margin: 0;
  }
  p {
    margin-bottom: 1rem;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    b {
      font-weight: 900;
    }
  }
  span {
    font-weight: 300;
    margin-top: 2rem;
  }
`;

const MainSection = styled(Section)`
  background: ${(props) => props.theme.colors.background};
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  img {
    max-width: 20vw;
    &.logo {
      max-width: 30vw;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    img {
      max-width: 50vw;
    }
  }
  @media (max-width: 1140px) {
    flex-direction: column;
    img {
      max-width: 65vw;
    }
  }
`;
const SecondSection = styled(Section)`
  background: black;
  background: ${(props) => props.theme.colors.backgrounddark};
  background: ${(props) => props.theme.colors.text};
  background: ${(props) => props.theme.colors.textlight};
  color: ${(props) => props.theme.colors.background};
  align-items: center;
  justify-content: start;
  section {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 1fr;
  }
  p {
    font-family: Raleway, sans-serif;
    font-weight: 300;
    padding: 1rem;
    font-size: 1.25rem;
    line-height: 2.5rem;
    @media (max-width: 1040px) {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }
`;
const FirstSection = styled(Section)`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.secondary};
  align-items: center;
  justify-content: center;
  text-align: start;
  ul {
    text-align: start;
  }
  section {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 1fr;
  }
  p {
    font-family: Raleway, sans-serif;
    font-weight: 300;
    padding: 1rem;
    font-size: 1.25rem;
    line-height: 2.5rem;
  }
`;

const ThirdSection = styled(Section)`
  background: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.background};
  padding-left: 10%;
  padding-right: 10%;
  ul {
    font-family: Raleway, sans-serif;
    text-align: start;
    font-weight: 300;
    padding: 1rem;
    font-size: 1.25rem;
    line-height: 2.5rem;
    @media (max-width: 1040px) {
      line-height: 1.75rem;
    }
    li {
      line-height: 3.5rem;
      @media (max-width: 1040px) {
        line-height: 1.75rem;
      }
    }
  }
`;

const Half = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  width: 100%;
  h3 {
    font-family: PhosphateSolid;
    font-size: 3rem;
    margin: 0;
    color: white;
  }
`;

const SaveLogoOverlap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  h3:first-of-type {
    span:last-of-type {
      color: ${(props) => props.theme.colors.primary};
    }
    span:first-of-type {
      opacity: 0.2;
    }
  }
  h3:last-of-type {
    line-height: 1rem;
    color: ${(props) => props.theme.colors.primary};
    ::after {
      content: "E";
      position: absolute;
    }
  }
`;

const SplurgeLogoOverlap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h3:first-of-type {
    span:first-of-type {
      color: ${(props) => props.theme.colors.secondary};
    }
    span:last-of-type {
      opacity: 0.2;
    }
  }
  h3:last-of-type {
    color: ${(props) => props.theme.colors.secondary};
    line-height: 1rem;
  }
`;

const Savings = styled(Half)`
  background: ${(props) => color(props.theme.colors.primary).alpha(0.3).toString()};
  /* background: rgba(255, 255, 255, 0.3); */
  p.overview {
    font-family: Raleway, sans-serif;
    font-weight: 300;
    padding: 1rem;
    font-size: 1.25rem;
    line-height: 2.5rem;
  }
`;
const Splurging = styled(Half)`
  background: ${(props) => color(props.theme.colors.secondary).alpha(0.3).toString()};
  /* background: rgba(255, 255, 255, 0.3); */
`;

const Save = styled.span`
  font-family: PhosphateSolid;
  color: ${(props) => props.theme.colors.primary};
`;
const Splurge = styled.span`
  font-family: PhosphateSolid;
  color: ${(props) => props.theme.colors.secondary};
`;

const HowDoesItWork = styled.p`
  font-size: 1.75rem !important;

  @media (max-width: 1140px) {
    font-size: 1rem !important;
  }
`;

const Formula = styled.div`
  font-size: 2rem;

  @media (max-width: 1140px) {
    font-size: 1rem !important;
  }
  padding: 0rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  span {
    margin: 0;
  }
  i {
    position: relative;
    font-style: unset;
  }
`;

// const Footer = styled.footer`
//   background: black;
//   color: ${(props) => props.theme.colors.primary};
//   padding: 2rem;
//   font-family: Raleway, sans-serif;
//   font-weight: 300;
//   padding: 1rem;
//   font-size: 1.25rem;
//   line-height: 2.5rem;
// `;

const LabeledIcon = styled.i<{ content: string }>`
  position: relative;
  @media (max-width: 1140px) {
    min-width: unset;
    max-width: unset;
  }
  min-height: 32px;
  max-height: 32px;
  &.save {
    color: ${(props) => props.theme.colors.primary};
    font-weight: 900;
    ::after {
      font-weight: 900;
    }
  }
  &.splurge {
    color: ${(props) => props.theme.colors.secondary};
    font-weight: 900;
    ::after {
      font-weight: 900;
    }
  }
  ::after {
    content: ${(props) => `"${props.content}"`};
    display: inline-block;
    white-space: nowrap;
    overflow-x: hidden;
    position: absolute;
    left: 0%;
    bottom: -1rem;
    font-size: 0.75rem;
    @media (max-width: 1140px) {
      font-size: 0.5rem !important;
    }
    font-family: Raleway, sans-serif;
    font-weight: 300;
  }
`;

const Group = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  grid-template-rows: auto auto;
  margin: 0 5%;
  align-self: center;
  justify-self: center;
  @media (max-width: 1140px) {
    display: grid;
    grid-template-columns: unset;
    grid-row-gap: 8px;
    grid-column-gap: 8px;
    grid-template-rows: auto auto auto auto;
    margin: 0 0;
  }

  p {
    font-size: 1rem;
    @media (max-width: 700px) {
      font-size: 0.875em;
    }
  }
`;

const GroupCorner = styled.div`
  padding: 0.5rem;

  background: rgba(0, 0, 0, 0.1);

  color: ${(props) => props.theme.colors.secondary};
  p {
    font-family: Raleway, sans-serif;
    font-weight: 300;
    padding: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.25rem;
    line-height: 2.5rem;
  }
  h2 {
    font-family: PhosphateSolid;

    color: ${(props) => props.theme.colors.secondary};
    i {
      margin-left: 1rem;
    }
    @media (max-width: 1040px) {
      font-size: 1.25rem;
    }
  }
  div {
    @media (max-width: 1140px) {
      display: none;
    }
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    i {
      font-size: 2rem;
    }
  }
`;

const SaveSpan = styled.span`
  b {
    display: inline;
  }
  display: inline;
  font-weight: 500;
  color: ${(props) => props.theme.colors.secondary};
  padding: 4px 8px;
  background-color: ${(props) => props.theme.colors.primary};
`;

const SplurgeSpan = styled.span`
  b {
    display: inline;
  }
  display: inline;
  color: ${(props) => props.theme.colors.primary};
  padding: 4px 8px;
  background-color: ${(props) => props.theme.colors.secondary};
`;

interface FloatingNavProps {}
const FloatingNav: React.FC<FloatingNavProps> = () => {
  const [activePage, _setActivePage] = useState(0);

  const setActivePage = (page: number) => {
    window.scrollTo({ top: window.innerHeight * page });
  };

  useEffect(() => {
    console.log("window.innerHeight", window.innerHeight);
    const windowHeight = window.innerHeight;
    const handleScroll = () => {
      const scrollOffset = window.scrollY;
      console.log("window.scrollY", window.scrollY);
      const page = Math.floor(scrollOffset / windowHeight);
      _setActivePage(page);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log("activePage", activePage);

  return (
    <FloatingNavDiv>
      <ul>
        <p
          onClick={() => setActivePage(0)}
          className={`${activePage === 0 ? "activewhite" : "not"}`}
        >
          Splurv
        </p>
        <p onClick={() => setActivePage(1)} className={`${activePage === 1 ? "active" : "not"}`}>
          What
        </p>
        <p onClick={() => setActivePage(2)} className={`${activePage === 2 ? "active" : "not"}`}>
          Why
        </p>
        <p onClick={() => setActivePage(3)} className={`${activePage === 3 ? "active" : "not"}`}>
          How
        </p>
      </ul>
    </FloatingNavDiv>
  );
};

function App() {
  return (
    <>
      <div className="App">
        <FloatingNav />
        <MainSection>
          <img alt="demo" src={demo} />
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img alt="logo" className="logo" src={logo} />
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
              <p>
                <SaveSpan>
                  Intentional <b>saving. </b>
                </SaveSpan>
              </p>
              <p>
                <SplurgeSpan>
                  Intentional <b>splurging</b>.{" "}
                </SplurgeSpan>
              </p>
            </div>
            <span>The antibudget app that helps you save first and spend guilt-free</span>
          </div>
        </MainSection>
        <FirstSection>
          <h2>What is it?</h2>
          <p className="hide-on-mobile">
            Splurv, at its core, is <b>an antibudget app.</b> There is plenty of literature on what
            an antibudget is, but the idea is simple:
            <ol>
              <li>
                Pay yourself first (set aside a portion of your paycheck for <b>savings</b> before
                anything else)
              </li>
              <li>Pay your bills (take care of rent, loans, car payments)</li>
              <li>
                Spend the rest however you wish, otherwise known as <b>splurging</b>
              </li>
            </ol>
            <p>
              As humans, we are irrational, unpredictable creatures that thrive on autonomy.
              <br />A budget based on predictive bucketing of categories is none of those things. So
              we made Splurv.
            </p>
          </p>
          <p className="show-on-mobile" style={{ margin: 0, padding: 0 }}>
            Splurv, at its core, is <b>an antibudget app</b>. Save first, pay bills, splurge away,
            in that order. <br />
            <br />
            <p style={{ margin: 0, padding: 0 }}>
              As humans, we are irrational, unpredictable creatures that thrive on autonomy.
              <br />A budget based on predictive bucketing of categories is none of those things. So
              we made Splurv.
            </p>
          </p>
          <Group>
            <GroupCorner>
              <h2>
                Analysis-driven savings
                <i className="fas fa-chart-bar" />
              </h2>
              <div>
                <p>
                  Breakdown your historical spending and gain insight into how much it costs to
                  exist. How much to save becomes science, not guessing.
                </p>
              </div>
            </GroupCorner>
            <GroupCorner>
              <h2>
                Credit Card Tracking
                <i className="fal fa-credit-card" />
              </h2>
              <div>
                <p>
                  Demystify what you owe and when with simplified statement analysis of your credit
                  cards. Know you'll have enough left instead of hoping
                </p>
              </div>
            </GroupCorner>
            <GroupCorner>
              <h2>
                Daily Splurge Value
                <i className="fal fa-shopping-bag" />
              </h2>

              <div>
                <p>
                  Only one number really matters and that's how much you can spend today. New TV or
                  two hundred Ramen Noodle Cups, doesn't matter.
                </p>
              </div>
            </GroupCorner>
            <GroupCorner>
              <h2>
                Steps to Recover
                <i className="fal fa-coins" />
              </h2>
              <div>
                <p>
                  Life happens so when it does, know what it takes (ahead of time) to recover. A
                  busted budget is no longer an excuse to be reckless.
                </p>
              </div>
            </GroupCorner>
          </Group>
        </FirstSection>
        <ThirdSection>
          <h2>Why are We Different</h2>
          <ul>
            <li>
              We put <b>savings at the forefront</b>, not the fringes. Saving should be intentional
              and transparent, not some black magic transfer that happens when you buy things.
            </li>
            <li>
              We start with what is concrete. Your fixed monthly commitments and your historical
              spending. <b>No more guessing.</b>
            </li>
            <li>
              We don't touch or see your bank accounts. You worked hard for that money, don't let
              other hands in your cookie jar.
            </li>
          </ul>
          <section></section>
        </ThirdSection>
        <SecondSection>
          <h2>how does it work</h2>
          <HowDoesItWork className="p-0 m-0 hide-on-mobile">
            Splurv provides you with two important numbers: How much you can{" "}
            <Save>save (per month)</Save> and how much you can <Splurge>splurge (per day)</Splurge>
          </HowDoesItWork>
          <HowDoesItWork className="p-0 m-0 show-on-mobile">
            Splurv provides you with two important numbers: <br />
            How much you can <Save>save (per month)</Save> <br /> How much you can{" "}
            <Splurge>splurge (per day)</Splurge>
          </HowDoesItWork>
          <section className="show-on-mobile" style={{ display: "flex", flexDirection: "column" }}>
            <Save>save (per month)</Save>
            <p style={{ marginTop: 0, paddingTop: 0 }}>
              We calculate how much you <i>can</i> save by first getting to your{" "}
              <b>monthly cost of existence</b> (bills, loans, groceries). <br />
              We then let you triage your historical credit card spending and determine how much fun
              money you want per month based on previous spending habits.
            </p>
            <Splurge>splurge (per day)</Splurge>
            <p style={{ marginTop: 0, paddingTop: 0 }}>
              Knowing when your pay days and bill due dates are, and with simplified analysis of
              your credit statements, we can help you see <i>exactly</i> how much you can spend
              today to not run out of money.
            </p>
          </section>
          <section className="hide-on-mobile">
            <Savings>
              <SaveLogoOverlap>
                <h3>
                  <span>Splur</span>
                  <span>v</span>
                </h3>
                <h3>Sav</h3>
              </SaveLogoOverlap>
              <p className="overview">
                We all have some minimum <b>monthly cost of existing (MCOE)</b> whether we know it
                or not. Your MCOE (rent, electricity, loans, basic groceries) is the minimum you
                need to get by, and it can help narrow down the range of what is possible to save.
              </p>
              <Formula>
                <LabeledIcon
                  className="fal fa-money-check-edit-alt"
                  content={"average income per month"}
                />
                <i className="fal fa-minus" />
                <LabeledIcon content={"monthly cost of existing"}>MCOE</LabeledIcon>

                <i className="fal fa-equals" />
                <LabeledIcon className="fal fa-sack-dollar" content={"max savings"} />
              </Formula>
              <p className="overview">
                This creates realistic and accurate expectations of what you can afford to save.
                Factor in how much of this max-savings you want towards fun money
              </p>
              <Formula>
                <LabeledIcon className="fal fa-sack-dollar" content={"max savings"} />
                <i className="fal fa-minus" />
                <LabeledIcon className="fal fa-umbrella-beach" content={"desired fun"} />

                <i className="fal fa-equals" />
                <LabeledIcon className="fal fa-piggy-bank save" content={"actual savings"} />
              </Formula>
            </Savings>
            <Splurging>
              <SplurgeLogoOverlap>
                <h3>
                  <span>Splur</span>
                  <span>v</span>
                </h3>
                <h3>Splurge</h3>
              </SplurgeLogoOverlap>
              <p className="overview">
                Once we have separated out our savings, we only need to focus on what's left
              </p>
              <Formula>
                <LabeledIcon
                  className="fal fa-money-check-edit-alt"
                  content={"average income per month"}
                />
                <i className="fal fa-minus" />
                <LabeledIcon className="fal fa-piggy-bank save" content={"actual savings"} />
                <i className="fal fa-equals" />
                <LabeledIcon className="fal fa-wallet" content={"money to spend"} />
              </Formula>
              <p className="overview">
                Splurv helps you demystify confusing credit card statements and lets you know{" "}
                <i>exactly</i> how your spending today will impact your next statement. By knowing
                which days you get paid, which days you rent is due, what reoccuring subscriptions
                you have, and how your variable credit/debit card spending is tracking, Splurv can
                help keep you within bounds by providing you a "Splurge Today" value.
              </p>
              <Formula>
                <LabeledIcon className="fal fa-wallet" content={"money to spend"} />
                <i className="fal fa-minus" />
                <LabeledIcon className="fal fa-file-invoice" content={"bills"} />
                <i className="fal fa-minus" />
                <LabeledIcon className="fal fa-credit-card" content={"current purchases"} />
                <i className="fal fa-equals" />
                <LabeledIcon className="fal fa-shopping-bag splurge" content={"splurge today"} />
              </Formula>
            </Splurging>
          </section>
        </SecondSection>
      </div>
    </>
  );
}

export default App;
